<template>
	<section>
		<div class="container">
			<div class="row py-5">
				<div class="col-md-6 d-flex flex-column justify-content-center align-items-center"><a target="_blank" href="https://www.google.com/search?client=opera&hs=t1I&sa=X&sca_esv=66aa45823c7df48d&hl=fr-BE&tbm=lcl&sxsrf=AHTn8zqsQQPh9rJ6oFwS6RvQPFCccPw3hg:1740246722159&q=PAWPAW+Avis&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNDIzMjUyNzc0NrI0MLc0NDIyNNrAyPiKkTvAMRyIFBzLMosXsSLzAM8HgpM3AAAA&rldimm=12625277132907912212&ved=2ahUKEwisl8id7NeLAxUgTqQEHU7kM5kQ9fQKegQIWBAF&biw=1866&bih=946&dpr=1#lkt=LocalPoiReviews"><img src="@/img/score.png"></a>
					<h2
						class="h1 my-5" style="color:#654897;text-align:center;"> Témoignages <br> de Satisfaction </h2>
				</div>
				<div class="col-md-6 d-flex align-items-center"><a target="_blank" href="https://www.google.com/search?client=opera&hs=t1I&sa=X&sca_esv=66aa45823c7df48d&hl=fr-BE&tbm=lcl&sxsrf=AHTn8zqsQQPh9rJ6oFwS6RvQPFCccPw3hg:1740246722159&q=PAWPAW+Avis&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNDIzMjUyNzc0NrI0MLc0NDIyNNrAyPiKkTvAMRyIFBzLMosXsSLzAM8HgpM3AAAA&rldimm=12625277132907912212&ved=2ahUKEwisl8id7NeLAxUgTqQEHU7kM5kQ9fQKegQIWBAF&biw=1866&bih=946&dpr=1#lkt=LocalPoiReviews"><img style="max-width:100%;" src="@/img/latest.png"></a></div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {

	}
</script>